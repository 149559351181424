/*-----------------------------------------------------------------------------
*
*	ACF Blocks
*
*----------------------------------------------------------------------------*/

// All block components.
.acf-block-component {

	.components-placeholder {
		margin: 0;
	}
}

// Block fields
.acf-block-component .acf-block-fields {
	// Ensure white background behind fields.
	background: #fff;

	// Generic body styles
	text-align: left;
	font-size: 13px;
	line-height: 1.4em;
	color: #444;
	font-family:
		-apple-system,
		BlinkMacSystemFont,
		"Segoe UI",
		Roboto,
		Oxygen-Sans,
		Ubuntu,
		Cantarell,
		"Helvetica Neue",
		sans-serif;

	&.acf-empty-block-fields {
		border: 1px solid #1e1e1e;
		padding: 12px;

		.components-panel & {
			border: none;
			border-top: 1px solid #ddd;
			border-bottom: 1px solid #ddd;
		}
	}

	html[dir="rtl"] & {
		text-align: right;
	}

	p {
		font-size: 13px;
		line-height: 1.5;
	}
}

// Block body.
.acf-block-body {
	// Fields wrapper.
	.acf-block-fields {
		border: #adb2ad solid 1px;

		// Tab
		.acf-tab-wrap {

			.acf-tab-group {
				margin-left: 0;
				padding: 16px 20px 0;
			}
		}
	}

	// Block fields (div).
	.acf-fields > .acf-field {
		padding: 16px 20px;

		// Accordions.
		&.acf-accordion {
			border-color: #adb2ad;

			.acf-accordion-title {
				padding: 16px 20px;
			}
		}
	}

	// Preview.
	.acf-block-preview {
		min-height: 10px;
	}
}

// Block panel.
.acf-block-panel {
	// Fields wrapper.
	.acf-block-fields {
		border-top: #ddd solid 1px;
		border-bottom: #ddd solid 1px;
		min-height: 1px;

		&:empty {
			border-top: none;
		}

		// Tab
		.acf-tab-wrap {
			background: transparent;
		}
	}
}

// Add compatibility for WP 5.3 and older.
// - Sidebar area is wrapped in a PanelBody element.
.components-panel__body .acf-block-panel {
	margin: 16px -16px -16px;
}
